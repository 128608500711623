import _classCallCheck from "@babel/runtime/helpers/esm/classCallCheck";
import _createClass from "@babel/runtime/helpers/esm/createClass";
import _inherits from "@babel/runtime/helpers/esm/inherits";
import _possibleConstructorReturn from "@babel/runtime/helpers/esm/possibleConstructorReturn";
import _getPrototypeOf from "@babel/runtime/helpers/esm/getPrototypeOf";

function _createSuper(Derived) { var hasNativeReflectConstruct = _isNativeReflectConstruct(); return function _createSuperInternal() { var Super = _getPrototypeOf(Derived), result; if (hasNativeReflectConstruct) { var NewTarget = _getPrototypeOf(this).constructor; result = Reflect.construct(Super, arguments, NewTarget); } else { result = Super.apply(this, arguments); } return _possibleConstructorReturn(this, result); }; }

function _isNativeReflectConstruct() { if (typeof Reflect === "undefined" || !Reflect.construct) return false; if (Reflect.construct.sham) return false; if (typeof Proxy === "function") return true; try { Boolean.prototype.valueOf.call(Reflect.construct(Boolean, [], function () {})); return true; } catch (e) { return false; } }

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import NodeGroup from '../NodeGroup';
import { numeric } from '../utils';

var keyAccessor = function keyAccessor() {
  return '$$key$$';
};

var Animate = function (_Component) {
  _inherits(Animate, _Component);

  var _super = _createSuper(Animate);

  function Animate() {
    _classCallCheck(this, Animate);

    return _super.apply(this, arguments);
  }

  _createClass(Animate, [{
    key: "render",
    value: function render() {
      var _this$props = this.props,
          show = _this$props.show,
          start = _this$props.start,
          enter = _this$props.enter,
          update = _this$props.update,
          leave = _this$props.leave,
          interpolation = _this$props.interpolation,
          children = _this$props.children;
      var data = typeof start === 'function' ? start() : start;
      return React.createElement(NodeGroup, {
        data: show ? [data] : [],
        start: function start() {
          return data;
        },
        keyAccessor: keyAccessor,
        interpolation: interpolation,
        enter: typeof enter === 'function' ? enter : function () {
          return enter;
        },
        update: typeof update === 'function' ? update : function () {
          return update;
        },
        leave: typeof leave === 'function' ? leave : function () {
          return leave;
        }
      }, function (nodes) {
        if (!nodes[0]) {
          return null;
        }

        var renderedChildren = children(nodes[0].state);
        return renderedChildren && React.Children.only(renderedChildren);
      });
    }
  }]);

  return Animate;
}(Component);

Animate.propTypes = process.env.NODE_ENV !== "production" ? {
  show: PropTypes.bool,
  interpolation: PropTypes.func,
  start: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  enter: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.object]),
  update: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.object]),
  leave: PropTypes.oneOfType([PropTypes.func, PropTypes.array, PropTypes.object]),
  children: PropTypes.func.isRequired
} : {};
Animate.defaultProps = {
  show: true,
  interpolation: numeric
};
export default Animate;