import * as TrackPlayer from './track-player';
export var IOSCategory;
(function (IOSCategory) {
    IOSCategory["Playback"] = "playback";
    IOSCategory["PlayAndRecord"] = "playAndRecord";
    IOSCategory["MultiRoute"] = "multiRoute";
    IOSCategory["Ambient"] = "ambient";
    IOSCategory["SoloAmbient"] = "soloAmbient";
    IOSCategory["Record"] = "record";
})(IOSCategory || (IOSCategory = {}));
export var IOSCategoryMode;
(function (IOSCategoryMode) {
    IOSCategoryMode["Default"] = "default";
    IOSCategoryMode["GameChat"] = "gameChat";
    IOSCategoryMode["Measurement"] = "measurement";
    IOSCategoryMode["MoviePlayback"] = "moviePlayback";
    IOSCategoryMode["SpokenAudio"] = "spokenAudio";
    IOSCategoryMode["VideoChat"] = "videoChat";
    IOSCategoryMode["VideoRecording"] = "videoRecording";
    IOSCategoryMode["VoiceChat"] = "voiceChat";
    IOSCategoryMode["VoicePrompt"] = "voicePrompt";
})(IOSCategoryMode || (IOSCategoryMode = {}));
export var IOSCategoryOptions;
(function (IOSCategoryOptions) {
    IOSCategoryOptions["MixWithOthers"] = "mixWithOthers";
    IOSCategoryOptions["DuckOthers"] = "duckOthers";
    IOSCategoryOptions["InterruptSpokenAudioAndMixWithOthers"] = "interruptSpokenAudioAndMixWithOthers";
    IOSCategoryOptions["AllowBluetooth"] = "allowBluetooth";
    IOSCategoryOptions["AllowBluetoothA2DP"] = "allowBluetoothA2DP";
    IOSCategoryOptions["AllowAirPlay"] = "allowAirPlay";
    IOSCategoryOptions["DefaultToSpeaker"] = "defaultToSpeaker";
})(IOSCategoryOptions || (IOSCategoryOptions = {}));
export var RatingType;
(function (RatingType) {
    RatingType[RatingType["Heart"] = TrackPlayer.RATING_HEART] = "Heart";
    RatingType[RatingType["ThumbsUpDown"] = TrackPlayer.RATING_THUMBS_UP_DOWN] = "ThumbsUpDown";
    RatingType[RatingType["ThreeStars"] = TrackPlayer.RATING_3_STARS] = "ThreeStars";
    RatingType[RatingType["FourStars"] = TrackPlayer.RATING_4_STARS] = "FourStars";
    RatingType[RatingType["FiveStars"] = TrackPlayer.RATING_5_STARS] = "FiveStars";
    RatingType[RatingType["Percentage"] = TrackPlayer.RATING_PERCENTAGE] = "Percentage";
})(RatingType || (RatingType = {}));
export var Capability;
(function (Capability) {
    Capability[Capability["Play"] = TrackPlayer.CAPABILITY_PLAY] = "Play";
    Capability[Capability["PlayFromId"] = TrackPlayer.CAPABILITY_PLAY_FROM_ID] = "PlayFromId";
    Capability[Capability["PlayFromSearch"] = TrackPlayer.CAPABILITY_PLAY_FROM_SEARCH] = "PlayFromSearch";
    Capability[Capability["Pause"] = TrackPlayer.CAPABILITY_PAUSE] = "Pause";
    Capability[Capability["Stop"] = TrackPlayer.CAPABILITY_STOP] = "Stop";
    Capability[Capability["SeekTo"] = TrackPlayer.CAPABILITY_SEEK_TO] = "SeekTo";
    Capability[Capability["Skip"] = TrackPlayer.CAPABILITY_SKIP] = "Skip";
    Capability[Capability["SkipToNext"] = TrackPlayer.CAPABILITY_SKIP_TO_NEXT] = "SkipToNext";
    Capability[Capability["SkipToPrevious"] = TrackPlayer.CAPABILITY_SKIP_TO_PREVIOUS] = "SkipToPrevious";
    Capability[Capability["JumpForward"] = TrackPlayer.CAPABILITY_JUMP_FORWARD] = "JumpForward";
    Capability[Capability["JumpBackward"] = TrackPlayer.CAPABILITY_JUMP_BACKWARD] = "JumpBackward";
    Capability[Capability["SetRating"] = TrackPlayer.CAPABILITY_SET_RATING] = "SetRating";
    Capability[Capability["Like"] = TrackPlayer.CAPABILITY_LIKE] = "Like";
    Capability[Capability["Dislike"] = TrackPlayer.CAPABILITY_DISLIKE] = "Dislike";
    Capability[Capability["Bookmark"] = TrackPlayer.CAPABILITY_BOOKMARK] = "Bookmark";
})(Capability || (Capability = {}));
export var Event;
(function (Event) {
    Event["PlaybackState"] = "playback-state";
    Event["PlaybackError"] = "playback-error";
    Event["PlaybackQueueEnded"] = "playback-queue-ended";
    Event["PlaybackTrackChanged"] = "playback-track-changed";
    Event["RemotePlay"] = "remote-play";
    Event["RemotePlayId"] = "remote-play-id";
    Event["RemotePlaySearch"] = "remote-play-search";
    Event["RemotePause"] = "remote-pause";
    Event["RemoteStop"] = "remote-stop";
    Event["RemoteSkip"] = "remote-skip";
    Event["RemoteNext"] = "remote-next";
    Event["RemotePrevious"] = "remote-previous";
    Event["RemoteJumpForward"] = "remote-jump-forward";
    Event["RemoteJumpBackward"] = "remote-jump-backward";
    Event["RemoteSeek"] = "remote-seek";
    Event["RemoteSetRating"] = "remote-set-rating";
    Event["RemoteDuck"] = "remote-duck";
    Event["RemoteLike"] = "remote-like";
    Event["RemoteDislike"] = "remote-dislike";
    Event["RemoteBookmark"] = "remote-bookmark";
})(Event || (Event = {}));
export var TrackType;
(function (TrackType) {
    TrackType["Default"] = "default";
    TrackType["Dash"] = "dash";
    TrackType["HLS"] = "hls";
    TrackType["SmoothStreaming"] = "smoothstreaming";
})(TrackType || (TrackType = {}));
export var PitchAlgorithm;
(function (PitchAlgorithm) {
    PitchAlgorithm[PitchAlgorithm["Linear"] = TrackPlayer.PITCH_ALGORITHM_LINEAR] = "Linear";
    PitchAlgorithm[PitchAlgorithm["Music"] = TrackPlayer.PITCH_ALGORITHM_MUSIC] = "Music";
    PitchAlgorithm[PitchAlgorithm["Voice"] = TrackPlayer.PITCH_ALGORITHM_VOICE] = "Voice";
})(PitchAlgorithm || (PitchAlgorithm = {}));
export var State;
(function (State) {
    State[State["None"] = TrackPlayer.STATE_NONE] = "None";
    State[State["Ready"] = TrackPlayer.STATE_READY] = "Ready";
    State[State["Playing"] = TrackPlayer.STATE_PLAYING] = "Playing";
    State[State["Paused"] = TrackPlayer.STATE_PAUSED] = "Paused";
    State[State["Stopped"] = TrackPlayer.STATE_STOPPED] = "Stopped";
    State[State["Buffering"] = TrackPlayer.STATE_BUFFERING] = "Buffering";
    State[State["Connecting"] = TrackPlayer.STATE_CONNECTING] = "Connecting";
})(State || (State = {}));
